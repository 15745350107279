<template>
  <div class="main">
    <el-card
        class="card-sty"
      shadow="hover"
      body-style="height:100%;display: flex;flex-direction: column;justify-content: space-between;"
    >
        <div v-auth="'relayControlPoint-add'">
          <el-button type="primary" size="small" @click="addRelayControlPoint">新增继电器控制点</el-button>
          &nbsp;
        </div>
            <el-table
        header-row-class-name="my-table-head-style"
        style="margin-top: 0; margin-bottom: 0; flex: 1;margin-top: 14px;"
        height="100%"
                v-loading="spinning"
                :data="data"
                row-key="id"
            >
              <el-table-column type="expand">
                <template slot-scope="props">
                  <el-form label-position="left" class="demo-table-expand">
                    <el-form-item label="关联继电器">
                      <span>{{ props.row.relayModelParentName }}</span>
                    </el-form-item>
                    <el-form-item label="关联液位传感器">
                      <span>{{ props.row.measurePointName }}</span>
                    </el-form-item>
                    <el-form-item label="自动关闭">
                      <span>{{ props.row.autoClose ==true?'是':props.row.autoClose ==false?'否':''}}</span>
                    </el-form-item>
                    <el-form-item label="最低液位">
                      <span>{{ props.row.liquidLevelNum }}</span>
                    </el-form-item>
                  </el-form>
                </template>
              </el-table-column>
              <el-table-column
                  prop="information"
                  label="第几路继电器">
              </el-table-column>
              <el-table-column
                  prop="relayModelDeviceId"
                  label="绑定继电器">
              </el-table-column>
              <el-table-column
                  prop="type"
                  label="类型">
                <template slot-scope="scope">
                  {{scope.row.type | typeFilter}}
                </template>
              </el-table-column>
              <el-table-column
                  prop="name"
                  label="名称">
              </el-table-column>
              <el-table-column
                  prop="farmAreaName"
                  label="农场名称">
              </el-table-column>
              <el-table-column
                  fixed="right"
                  width="200"
                  label="操作"
                  align="center"
                  v-if="authVerify.verify('relayControlPoint-edit,relayControlPoint-delete')"
              >
                <template slot-scope="scope">
                  <el-button icon="el-icon-edit-outline" size="mini" v-auth="'relayControlPoint-edit'"
                             @click="editRelayControlPoint(scope.row)">编辑
                  </el-button>
                  <el-button icon="el-icon-delete" size="mini" v-auth="'relayControlPoint-delete'"
                             @click="deleteRelayControlPoint(scope.row)">
                    删除
                  </el-button>
                </template>
              </el-table-column>
            </el-table>
      </el-card>
    <!-- 添加 -->
    <template>
      <div>
        <a-drawer
            title="新增继电器控制点"
            width="450"
            placement="right"
            :closable="false"
            :visible="addRelayControlPointVisible"
            @close="addRelayControlPointVisible = false"
        >
          <div style="margin-bottom: 3.5rem">
            <a-form-model :model="relayControlPointFormValue" ref="addRefForm"
                          :rules="relayControlPointRules" :label-col="{ span: 6 }"
                          :wrapper-col="{ span: 17 }">
              <a-form-model-item label="名称" prop="name">
                <a-input
                    placeholder="请输入名称"
                    v-model="relayControlPointFormValue.name"
                />
              </a-form-model-item>
              <a-form-model-item label="关联继电器" prop="relayModelId">
                <a-select
                    v-model="relayControlPointFormValue.relayModelId"
                    placeholder="请选择农场基地"
                    allowClear
                    style="width: 100%"
                >
                  <a-select-option v-for="item in relayModelData" :key="item.id">
                   {{ item.relayDeviceId }}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
              <a-form-model-item label="第几路继电器" prop="information">
                <a-select  style="width: 100%" placeholder="请选择" v-model="relayControlPointFormValue.information">
                  <a-select-option v-for="(i,index) in 64" :key="i">
                    {{ i +'路'}}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
              <a-form-model-item label="农场基地" prop="farmAreaId">
                <a-select
                    v-model="relayControlPointFormValue.farmAreaId"
                    placeholder="请选择农场基地"
                    allowClear
                    style="width: 100%"
                >
                  <a-select-option v-for="item in farmAreaList" :key="item.id">
                    {{ item.fullName }}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
              <a-form-model-item label="设备类型" prop="type">
                <a-select
                    style="width: 100%"
                    allowClear
                    v-model="relayControlPointFormValue.type"
                    placeholder="请选择设备类型"
                    @change="typeChange"
                >
                  <a-select-option value="PUMP"> 抽水泵 </a-select-option>
                  <a-select-option value="VALVE"> 水阀 </a-select-option>
                  <a-select-option value="GENERAL"> 普通开关 </a-select-option>
                </a-select>
              </a-form-model-item>
              <div v-if="relayControlPointFormValue.type === 'PUMP'">
                <a-form-model-item label="关联液位传感器" prop="measurePointId">
                  <a-select
                      style="width: 100%"
                      allowClear
                      v-model="relayControlPointFormValue.measurePointId"
                      placeholder="请选择关联的液位传感器"
                  >
                    <a-select-option :value="item.id" v-for="item in liquidLevelMeasurePointList" :key="item.id"> {{item.customMeterAddress}}{{item.customMeterName}}#{{item.informationId}} </a-select-option>
                  </a-select>
                </a-form-model-item>
                <a-form-model-item label="最低液位(cm)" prop="liquidLevelNum">
                  <a-input
                      placeholder="请输入最低液位 请输入整数"
                      v-model="relayControlPointFormValue.liquidLevelNum"
                  />
                </a-form-model-item>
                <a-form-model-item label="到低位是否关闭" prop="autoClose">
                  <a-select
                      style="width: 100%"
                      allowClear
                      v-model="relayControlPointFormValue.autoClose"
                      placeholder="请选择到达最低液位是否自动关闭"
                  >
                    <a-select-option :value="true"> 是 </a-select-option>
                    <a-select-option :value="false"> 否 </a-select-option>
                  </a-select>
                </a-form-model-item>
              </div>

              <a-form-model-item
                  label="抽水泵"
                  prop="parent"
                  v-if="relayControlPointFormValue.type === 'VALVE'"
              >
                <a-select
                    style="width: 100%"
                    allowClear
                    v-model="relayControlPointFormValue.parent"
                    placeholder="请选择连接的抽水机!"
                >
                  <a-select-option
                      v-for="item in data"
                      :key="item.id"
                      v-if="item.type === 'PUMP'"
                  >
                    {{ item.name }}#{{ item.information }}
                  </a-select-option>
                </a-select>
              </a-form-model-item>

            </a-form-model>
          </div>
          <div
              :style="{
          position: 'absolute',
          right: 0,
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e9e9e9',
          padding: '10px 16px',
          background: '#fff',
          textAlign: 'right',
          zIndex: 1,
        }"
          >
            <a-button :style="{ marginRight: '8px' }" @click="addRelayControlPointVisible = false">
              取消
            </a-button>
            <a-button type="primary" @click="toAddRelayControlPoint">
              确认
            </a-button>
          </div>

        </a-drawer>
      </div>
    </template>
    <!-- 编辑 -->
    <template>
      <div>
        <a-drawer
            title="编辑继电器控制点"
            width="450"
            placement="right"
            :closable="false"
            :visible="editRelayControlPointVisible"
            @close="editRelayControlPointVisible = false"
        >
          <div style="margin-bottom: 3.5rem">
            <a-form-model :model="relayControlPointFormValue" ref="editRefForm"
                          :rules="relayControlPointRules" :label-col="{ span: 6 }"
                          :wrapper-col="{ span: 17 }">
              <a-form-model-item label="名称" prop="name">
                <a-input
                    placeholder="请输入名称"
                    v-model="relayControlPointFormValue.name"
                />
              </a-form-model-item>
              <a-form-model-item label="关联继电器" prop="relayModelId">
                <a-select
                    v-model="relayControlPointFormValue.relayModelId"
                    placeholder="请选择农场基地"
                    allowClear
                    style="width: 100%"
                >
                  <a-select-option v-for="item in relayModelData" :key="item.id">
                    {{ item.relayDeviceId }}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
              <a-form-model-item label="第几路继电器" prop="information">
                <a-select  style="width: 100%" placeholder="请选择" v-model="relayControlPointFormValue.information">
                  <a-select-option v-for="(i,index) in 64" :key="i">
                    {{ i +'路'}}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
              <a-form-model-item label="农场基地" prop="farmAreaId">
                <a-select
                    v-model="relayControlPointFormValue.farmAreaId"
                    placeholder="请选择农场基地"
                    allowClear
                    style="width: 100%"
                >
                  <a-select-option v-for="item in farmAreaList" :key="item.id">
                    {{ item.fullName }}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
              <a-form-model-item label="设备类型" prop="type">
                <a-select
                    style="width: 100%"
                    allowClear
                    v-model="relayControlPointFormValue.type"
                    placeholder="请选择设备类型"
                    @change="typeChange"
                >
                  <a-select-option value="PUMP"> 抽水泵 </a-select-option>
                  <a-select-option value="VALVE"> 水阀 </a-select-option>
                  <a-select-option value="GENERAL"> 普通开关 </a-select-option>
                </a-select>
              </a-form-model-item>
              <div v-if="relayControlPointFormValue.type === 'PUMP'">
                <a-form-model-item label="关联液位传感器" prop="measurePointId">
                  <a-select
                      style="width: 100%"
                      allowClear
                      v-model="relayControlPointFormValue.measurePointId"
                      placeholder="请选择关联的液位传感器"
                  >
                    <a-select-option :value="item.id" v-for="item in liquidLevelMeasurePointList" :key="item.id"> {{item.customMeterAddress}}{{item.customMeterName}}#{{item.informationId}} </a-select-option>
                  </a-select>
                </a-form-model-item>
                <a-form-model-item label="最低液位(cm)" prop="liquidLevelNum">
                  <a-input
                      placeholder="请输入最低液位 请输入整数"
                      v-model="relayControlPointFormValue.liquidLevelNum"
                  />
                </a-form-model-item>
                <a-form-model-item label="到达最低液位是否自动关闭" prop="autoClose">
                  <a-select
                      style="width: 100%"
                      allowClear
                      v-model="relayControlPointFormValue.autoClose"
                      placeholder="到低位是否关闭"
                  >
                    <a-select-option :value="true"> 是 </a-select-option>
                    <a-select-option :value="false"> 否 </a-select-option>
                  </a-select>
                </a-form-model-item>
              </div>

              <a-form-model-item
                  label="抽水泵"
                  prop="parent"
                  v-if="relayControlPointFormValue.type === 'VALVE'"
              >
                <a-select
                    style="width: 100%"
                    allowClear
                    v-model="relayControlPointFormValue.parent"
                    placeholder="请选择连接的抽水机!"
                >
                  <a-select-option
                      v-for="item in data"
                      :key="item.id"
                      v-if="item.type === 'PUMP'"
                  >
                    {{ item.name }}#{{ item.information }}
                  </a-select-option>
                </a-select>
              </a-form-model-item>

            </a-form-model>
          </div>
          <div
              :style="{
          position: 'absolute',
          right: 0,
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e9e9e9',
          padding: '10px 16px',
          background: '#fff',
          textAlign: 'right',
          zIndex: 1,
        }"
          >
            <a-button :style="{ marginRight: '8px' }" @click="editRelayControlPointVisible = false">
              取消
            </a-button>
            <a-button type="primary" @click="toEditRelayControlPoint">
              确认
            </a-button>
          </div>

        </a-drawer>
      </div>
    </template>
  </div>
</template>

<script>
    export default {
        data() {
            return {
                relayControlPointFormValue: {
                    id: "",
                    name: "",
                    farmAreaId: "",
                    information: "",
                    relayModelId: "",
                    parent: [],
                    type: [],
                    measurePointId: [],
                    autoClose: true,
                    liquidLevelNum: "",
                },
                relayControlPointRules: {
                    name: [{ required: true, message: "请输入设备名称!", trigger: "blur" }],
                    information: [{ required: true, message: "请选择第几路!", trigger: "blur" }],
                    relayModelId: [{ required: true, message: "请选择关联的继电器!", trigger: "blur" }],
                    farmAreaId: [
                        { required: true, message: "请选择农场基地!", trigger: "blur" },
                    ],
                    type: [{ required: true, message: "请选择设备类型!", trigger: "blur" }],
                    parent: [
                        { required: false, message: "请选择连接的抽水机!", trigger: "blur" },
                    ],
                },
                spinning: true,
                screenWidth: document.documentElement.clientWidth,//屏幕宽度
                screenHeight: document.documentElement.clientHeight - 50,//屏幕高度
                addRelayControlPointVisible: false,
                editRelayControlPointVisible: false,
                data: [],
                liquidLevelMeasurePointList: [],
                farmAreaList: [],
                relayModelData: [],
            }
        },
        watch: {
            'screenWidth': function (val) { //监听屏幕宽度变化
                console.log(val);
            },
            'screenHeight': function (val) { //监听屏幕高度变化
                console.log(val);
            },
        },
        filters: {
            typeFilter: function (value) {
                if (!value) return "";
                if (value === 'PUMP') {
                    return "抽水机";
                } else if (value === 'VALVE') {
                    return "水阀";
                } else if (value === 'GENERAL') {
                    return "普通";
                }
            }
        },
        mounted() {
            const _this = this;
            window.onresize = function () { // 定义窗口大小变更通知事件
                _this.screenWidth = document.documentElement.clientWidth; //窗口宽度
                _this.screenHeight = document.documentElement.clientHeight - 50; //窗口高度
            };
        },
        created() {
            this.getRelayControlPoint();
            this.getFarmAreaData();
            this.getLiquidLevelMeasurePointList();
            this.getRelayModelData();
        },
        methods: {
            getRelayModelData() {
                this.http.relay.getRelayModelList()
                    .then(response => {
                        if (response.data.status === 200) {
                            this.spinning = false;
                            this.relayModelData = response.data.data;
                        } else {
                            this.$message.warning('数据获取失败');
                        }
                    });
            },
            typeChange() {
                if (this.relayControlPointFormValue.type === "VALVE") {
                    console.log("1");
                    this.relayControlPointRules.parent[0].required = true;
                } else {
                    console.log("2");
                    this.relayControlPointRules.parent[0].required = false;
                }
            },
            async getLiquidLevelMeasurePointList() {
                const { data: res } = await this.http.device.getLiquidLevelMeasurePointList();
                if (res.status !== 200) {
                    this.$message.error(res.msg);
                }
                this.liquidLevelMeasurePointList = res.data;
            },
            getFarmAreaData() {
                this.http.farmArea.getFarmAreaList().then((response) => {
                    if (response.data.status == 200) {
                        this.farmAreaList = response.data.data;
                    } else {
                        this.$message.warning("数据获取失败");
                    }
                });
            },
            getRelayControlPoint() {
                this.http.relay.getRelayControlPointBaseList()
                    .then(response => {
                        if (response.data.status === 200) {
                            this.spinning = false;
                            this.data = response.data.data;
                        } else {
                            this.$message.warning('数据获取失败');
                        }
                    });
            },
            deleteRelayControlPoint(key) {
                let self = this;
                this.$confirm({
                    title: '确定删除吗?',
                    content: '',
                    okText: '确认',
                    cancelText: '取消',
                    onOk: () => {
                        this.http.relay.deleteRelayControlPoint({
                            id: key.id
                        })
                            .then(response => {
                                if (response.data.status === 200) {
                                    this.$message.success(response.data.msg);
                                    self.getRelayControlPoint();
                                } else {
                                    this.$message.error(response.data.msg);
                                }
                            })
                    },
                    onCancel() {
                    },
                });
            },

            addRelayControlPoint() {
                this.cleanRelayControlPoint();
                this.addRelayControlPointVisible = true;
                  this.$refs.addRefForm.resetFields();
            },
            toAddRelayControlPoint() {
                this.$refs.addRefForm.validate(valid => {
                    if (valid) {
                        console.log(this.relayControlPointFormValue);
                        this.http.relay.createRelayControlPoint(this.relayControlPointFormValue).then(response => {
                            if (response.data.status === 200) {
                                this.$message.success(response.data.msg);
                                this.addRelayControlPointVisible = false;
                            } else {
                                this.$message.warning(response.data.msg);
                            }
                            this.getRelayControlPoint();
                        })
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });
            },
            editRelayControlPoint(record) {
                console.log(record);
                this.cleanRelayControlPoint();
                this.relayControlPointFormValue = {
                    id: record.id,
                    name: record.name,
                    relayModelId: record.relayModelId,
                    information: record.information,
                    farmAreaId: record.farmAreaId,
                    type: record.type,
                    parent: record.parent,
                    measurePointId: record.measurePointId,
                    autoClose: record.autoClose,
                    liquidLevelNum: record.liquidLevelNum,
                };
                this.typeChange();
                this.editRelayControlPointVisible = true;
                  this.$refs.editRefForm.resetFields();
            },
            cleanRelayControlPoint() {
                this.relayControlPointFormValue = {
                    id: "",
                    name: "",
                    relayModelId: [],
                    information: [],
                    farmAreaId: [],
                    type: [],
                    parent: [],
                    measurePointId: [],
                    autoClose: true,
                    liquidLevelNum: "",
                };
            },

            toEditRelayControlPoint() {
                this.$refs.editRefForm.validate(valid => {
                    if (valid) {
                        this.http.relay.updateRelayControlPoint(this.relayControlPointFormValue)
                            .then(response => {
                                if (response.data.status === 200) {
                                    this.$message.success(response.data.msg);
                                    this.editRelayControlPointVisible = false;
                                } else {
                                    this.$message.warning(response.data.msg);
                                }
                                this.getRelayControlPoint();
                            })
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });
            },

        },
    }
</script>

<style scoped>
  /*滚动条整体样式*/
  .scroll_bar::-webkit-scrollbar {
    width: 8px; /*高宽分别对应横竖滚动条的尺寸*/
  }

  .scroll_bar::-webkit-scrollbar-thumb {
    /*滚动条里面小方块*/
    border-radius: 10px;
    background-color: #cecece;
  }

  .scroll_bar::-webkit-scrollbar-track {
    /*滚动条里面轨道*/
    border-radius: 10px;
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    background: #ededed;
  }

  .demo-table-expand >>> .el-form-item__label {
    color: #99a9bf;
    font-size: 8px;
  }

  .demo-table-expand >>> .el-form-item {
    margin-right: 0;
    margin-bottom: 0;
    width: 50%;
  }
</style>

<style lang="less" scoped>
* {
  box-sizing: border-box;
}
.main {
  padding: 14px;
  height: 100%;
  .card-sty {
    height: 100%;
  }
}
</style>
